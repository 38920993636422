import {
    Grid,
    Stack,
    Typography,
    Box,
    Select,
    MenuItem,
    IconButton,
    Button,
    CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import IosShareIcon from "@mui/icons-material/IosShare";

import {
    ButtonSelect,
    CustomSelectOutline,
    GridCard,
    RangePicker,
} from "../../../../common/components";
import { lineChartFake } from "./fake";
import { PerformanceLineChart } from "../components/PerformanceLineChart";
import { OrderTypesPeriods } from "../components/OrderTypesPeriods";
import {
    addDays,
    differenceInDays,
    format,
    subDays,
    subMonths,
    subYears,
} from "date-fns";
import { useEffect, useState } from "react";
import { services } from "../../services";
import { useMutation } from "@tanstack/react-query";
import {
    isDailyIteration,
    isHourlyIteration,
    isMonthlyIteration,
    isWeeklyIteration,
    isYearlyIteration,
} from "./utils";
import { ComparisonChart } from "./ComparisonChart";
import { useTranslation } from "react-i18next";
import { getToken, getUserStorage } from "../../../../common/utils";
import { constants } from "../../../../config/constants";
import { toast } from "react-toastify";
import { ExportButton } from "../../../../common/components/Export/Export";

const SalesPerformance = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
    const [overviewPDF, setOverviewPDF] = useState(null);

    const [isNoComparison, setIsNoComparison] = useState(false);
    const [period, setPeriod] = useState({
        startDate: format(new Date(), "y-M-d"),
        endDate: format(new Date(), "y-M-d"),
    });
    const [iteration, setIteration] = useState("daily");
    const [prevPeriod, setPrevPeriod] = useState("prev_period");
    const [prevPeriodDuration, setPrevPeriodDuration] = useState({
        startDate: format(new Date(), "y-M-d"),
        endDate: format(new Date(), "y-M-d"),
    });

    const [chartsData, setChartsData] = useState({
        AvgBasketQuantity: {
            current: [],
            previous: [],
        },
        ProfitGrowth: {
            current: [],
            previous: [],
        },
        AvgBasketValue: {
            current: [],
            previous: [],
        },
        SalesGrowth: {
            current: [],
            previous: [],
        },
        TotalTransactions: {
            current: [],
            previous: [],
        },
    });

    const user = getUserStorage();
    const isOwner = user.role === 1;
    const isGroupOwner = user.role === 9;
    const userBranches =
        isOwner && !user.manager_view ? user?.merchant?.branches : user?.branch;
    const allBranches = userBranches.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
    }));
    const isManagerView = user.manager_view;
    const allBranchesOption = { id: "all", label: t("common.all_branches") };
    const [selectedBranch, setSelectedBranch] = useState(
        isManagerView ? allBranches[0].id : allBranchesOption.id
    );

    const userGroups = user.group;
    const allGroups = userGroups?.map((group) => ({
        id: group.merchant_id,
        label: group.name,
    }));
    const allGroupsOption = { id: "all", label: t("common.all_groups") };
    const [selectedGroup, setSelectedGroup] = useState(allGroupsOption.id);

    const { mutate: getSalesPerformance, isLoading } = useMutation({
        mutationFn: services.getSalesPerformance,
        onSuccess: (res) => {
            if (res.data.response && !res.data.error) {
                const response = res.data.response;

                refineChartsData(response);
                setOverviewPDF({
                    performanceData: response,
                    period,
                });
            }

            if (res.data.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    function refineChartsData(dataArr) {
        const preCharts = {
            AvgBasketQuantity: {
                Current: [],
                Old: [],
            },
            ProfitGrowth: {
                Current: [],
                Old: [],
            },
            AvgBasketValue: {
                Current: [],
                Old: [],
            },
            SalesGrowth: {
                Current: [],
                Old: [],
            },
            TotalTransactions: {
                Current: [],
                Old: [],
            },
        };

        dataArr.sales_performance_profit.forEach((item) => {
            preCharts[item.figure_].Current.push({
                name: item.measure_desc,
                "Current Period":
                    item.status_ === "Current" ? item.measure_values_ : 0,
                "Previous Period":
                    item.status_ === "Old" ? item.measure_values_ : 0,
            });
        });
        dataArr.sales_performance_summary.forEach((item) => {
            preCharts[item.figure].Current.push({
                name: item.measure_desc,
                "Current Period":
                    item.status_ === "Current" ? item.measure_values : 0,
                "Previous Period":
                    item.status_ === "Old" ? item.measure_values : 0,
            });
        });

        setChartsData(preCharts);
    }

    useEffect(() => {
        setPeriod({ startDate: new Date(), endDate: new Date() });
        setIteration("daily");
        setPrevPeriodDuration({
            startDate: subDays(new Date(period.startDate), 1),
            endDate: subDays(new Date(period.startDate), 1),
        });
    }, []);

    useEffect(() => {
        if (period.startDate) {
            getSalesPerformance({
                current_from_date: period.startDate,
                current_till_date: period.endDate,
                old_from_date: format(
                    new Date(prevPeriodDuration.startDate),
                    "y-M-d"
                ),
                old_till_date: format(
                    new Date(prevPeriodDuration.endDate),
                    "y-M-d"
                ),
                duration: "daily",
                [`${isGroupOwner ? "merchant_filter" : "branch_id"}`]:
                    isGroupOwner ? selectedGroup : selectedBranch,
            });
        }
    }, []);

    const handleBranchChange = (e) => {
        const newSelectedBranchValue = e.target.value;
        setSelectedBranch(newSelectedBranchValue);

        getSalesPerformance({
            current_from_date: period.startDate,
            current_till_date: period.endDate,
            old_from_date: format(
                new Date(prevPeriodDuration.startDate),
                "y-M-d"
            ),
            old_till_date: format(
                new Date(prevPeriodDuration.endDate),
                "y-M-d"
            ),
            duration: "daily",
            branch_id: newSelectedBranchValue,
        });
    };

    const handleGroupChange = (e) => {
        const newSelectedGroupValue = e.target.value;
        setSelectedGroup(newSelectedGroupValue);

        getSalesPerformance({
            current_from_date: period.startDate,
            current_till_date: period.endDate,
            old_from_date: format(
                new Date(prevPeriodDuration.startDate),
                "y-M-d"
            ),
            old_till_date: format(
                new Date(prevPeriodDuration.endDate),
                "y-M-d"
            ),
            duration: "daily",
            merchant_filter: newSelectedGroupValue,
        });
    };

    const isAllTimeSelected =
        period.startDate &&
        period.startDate ===
            sessionStorage.getItem(constants.storage.firstDate);

    const isHourlyFilter = isHourlyIteration({
        startDate:
            period.startDate ||
            sessionStorage.getItem(constants.storage.firstDate),
        endDate: period.endDate,
    });
    const isDailyFilter = isDailyIteration({
        startDate:
            period.startDate ||
            sessionStorage.getItem(constants.storage.firstDate),
        endDate: period.endDate,
    });
    const isWeeklyFilter = isWeeklyIteration({
        startDate:
            period.startDate ||
            sessionStorage.getItem(constants.storage.firstDate),
        endDate: period.endDate,
    });
    const isMonthlyFilter = isMonthlyIteration({
        startDate:
            period.startDate ||
            sessionStorage.getItem(constants.storage.firstDate),
        endDate: period.endDate,
    });
    const isYearlyFilter = isYearlyIteration({
        startDate:
            period.startDate ||
            sessionStorage.getItem(constants.storage.firstDate),
        endDate: period.endDate,
    });

    const handleDatesChange = ({ startDate, endDate }) => {
        if (startDate !== "1970-1-1") {
            // setIteration(isHourlyapplicable ? "hourly" : "daily");
            // const isHourlyapplicable = isHourlyIteration({
            //     startDate: startDate,
            //     endDate: endDate,
            // });

            setPeriod({ startDate, endDate });
            setIteration("daily");

            handlePrevPeriodChange({
                value: "prev_period",
                currentStart: startDate,
                currentEnd: endDate,
                duration: "daily",
            });
        }
    };

    const handleIterationChange = (value) => {
        setIteration(value);
        getSalesPerformance({
            current_from_date: period.startDate,
            current_till_date: period.endDate,
            old_from_date: format(
                new Date(prevPeriodDuration.startDate),
                "y-M-d"
            ),
            old_till_date: format(
                new Date(prevPeriodDuration.endDate),
                "y-M-d"
            ),
            duration: value,
            [`${isGroupOwner ? "merchant_filter" : "branch_id"}`]: isGroupOwner
                ? selectedGroup
                : selectedBranch,
        });
    };

    const handlePrevPeriodChange = ({
        value,
        currentStart,
        currentEnd,
        durationValue,
    }) => {
        const currentStartDate = currentStart || period.startDate;
        const currentEndDate = currentEnd || period.endDate;

        let newPrevStartDate = prevPeriodDuration.startDate;
        let newPrevEndDate = prevPeriodDuration.endDate;

        setPrevPeriod(value);
        if (value === "no_comparison") setIsNoComparison(true);
        else setIsNoComparison(false);

        const daysDiff = differenceInDays(
            new Date(currentEndDate),
            new Date(currentStartDate)
        );

        if (value === "prev_period") {
            newPrevStartDate = subDays(
                new Date(currentStartDate),
                daysDiff || 1
            );
            newPrevEndDate = subDays(new Date(currentStartDate), 1);
            setPrevPeriodDuration({
                startDate: newPrevStartDate,
                endDate: newPrevEndDate,
            });
        }
        if (value === "prev_month") {
            newPrevStartDate = subMonths(new Date(currentStartDate), 1);
            newPrevEndDate = addDays(
                new Date(subMonths(new Date(currentStartDate), 1)),
                daysDiff
            );
            setPrevPeriodDuration({
                startDate: newPrevStartDate,
                endDate: newPrevEndDate,
            });
        }
        if (value === "prev_year") {
            newPrevStartDate = subYears(new Date(currentStartDate), 1);
            newPrevEndDate = addDays(
                new Date(subYears(new Date(currentStartDate), 1)),
                daysDiff
            );
            setPrevPeriodDuration({
                startDate: newPrevStartDate,
                endDate: newPrevEndDate,
            });
        }

        getSalesPerformance({
            current_from_date: currentStart || period.startDate,
            current_till_date: currentEnd || period.endDate,
            old_from_date: format(new Date(newPrevStartDate), "y-M-d"),
            old_till_date: format(new Date(newPrevEndDate), "y-M-d"),
            duration: durationValue || iteration,
            [`${isGroupOwner ? "merchant_filter" : "branch_id"}`]: isGroupOwner
                ? selectedGroup
                : selectedBranch,
        });
    };

    if (isAllTimeSelected && !isNoComparison) setIsNoComparison(true);

    useEffect(() => {
        window.pendo.pageLoad({
            name: "Sales Performance Page",
        });
    }, []);

    const generatePDF = async () => {
        setIsGeneratingPdf(true);
        try {
            const response = await fetch(
                // "http://localhost:3002/generate-pdf",
                "https://console.ballurh.io/generate-pdf",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        // pageURL: `http://localhost:3001/performancePDF/`,
                        pageURL: `${constants.baseURL}/performancePDF/`,
                        token: getToken(),
                        user: getUserStorage(),
                        overviewPDF: {
                            ...overviewPDF,
                            isNoComparison,
                            iteration,
                            prevPeriod,
                            isArabic,
                            printDate: `${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`,
                        },
                    }),
                }
            );

            if (response.ok) {
                // The PDF generation was successful
                const pdfBlob = await response.blob();
                const pdfUrl = URL.createObjectURL(pdfBlob);

                // Create a temporary link element to trigger the download
                const link = document.createElement("a");
                link.href = pdfUrl;
                link.download = `ballurh_overview_${new Date().getTime()}.pdf`; // Set the desired filename
                link.click();

                // Clean up the temporary URL object
                URL.revokeObjectURL(pdfUrl);
                setIsGeneratingPdf(false);
            } else {
                // Handle error cases
                console.error("PDF generation failed");
            }
        } catch (error) {
            // TODO: Pending on design
        }
    };

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <Grid container flexWrap="nowrap">
                <Grid item xs={6} marginRight={8}>
                    <Stack spacing={0}>
                        <Typography variant="h5" fontSize={30}>
                            {t("sales.performance")}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>

            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box display="flex" gap={3} alignItems="center">
                    <RangePicker
                        isToday
                        onChange={(value) => handleDatesChange(value)}
                    />

                    {!isAllTimeSelected && (
                        <>
                            <Typography variant="body2">
                                {t("common.compared_to")}
                            </Typography>

                            <Select
                                value={prevPeriod}
                                onChange={(e) =>
                                    handlePrevPeriodChange({
                                        value: e.target.value,
                                    })
                                }
                                input={<CustomSelectOutline />}
                                IconComponent={ExpandMoreIcon}
                            >
                                <MenuItem value="prev_period">
                                    {t("sales.previous_per")}
                                </MenuItem>
                                <MenuItem value="prev_month">
                                    {t("sales.previous_month")}
                                </MenuItem>
                                <MenuItem value="prev_year">
                                    {t("sales.previous_year")}
                                </MenuItem>
                                <MenuItem value="no_comparison">
                                    {t("sales.no_comparison")}
                                </MenuItem>
                            </Select>

                            {prevPeriod !== "no_comparison" && (
                                <Select
                                    value={iteration}
                                    onChange={(e) =>
                                        handleIterationChange(e.target.value)
                                    }
                                    input={<CustomSelectOutline />}
                                    IconComponent={UnfoldMoreIcon}
                                >
                                    {
                                        <MenuItem value="daily">
                                            {t("sales.daily")}
                                        </MenuItem>
                                    }
                                    {isWeeklyFilter && (
                                        <MenuItem value="weekly">
                                            {t("sales.weekly")}
                                        </MenuItem>
                                    )}
                                    {isMonthlyFilter && (
                                        <MenuItem value="monthly">
                                            {t("sales.monthly")}
                                        </MenuItem>
                                    )}
                                    {isYearlyFilter && (
                                        <MenuItem value="yearly">
                                            Yearly
                                        </MenuItem>
                                    )}
                                </Select>
                            )}
                        </>
                    )}
                </Box>

                <Box display="flex" alignItems="center" gap={3}>
                    {!isManagerView && !isGroupOwner && (
                        <ButtonSelect
                            value={selectedBranch}
                            onChange={handleBranchChange}
                            options={[allBranchesOption, ...allBranches]}
                        />
                    )}

                    {isGroupOwner && (
                        <ButtonSelect
                            value={selectedGroup}
                            onChange={handleGroupChange}
                            options={[allGroupsOption, ...allGroups]}
                        />
                    )}

                    <ExportButton
                        overviewPDF={{
                            ...overviewPDF,
                            isNoComparison,
                            iteration,
                            prevPeriod,
                            isArabic,
                            printDate: `${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`,
                        }}
                        pageName="performancePDF"
                        isLoading={isLoading}
                    />
                </Box>
            </Box>

            <Grid container spacing={0} flexWrap="nowrap">
                <ComparisonChart
                    label={t("sales.sales_growth")}
                    isNoComparison={isNoComparison}
                    data={chartsData.SalesGrowth.Current || []}
                    unit={t("common.currency")}
                />

                <ComparisonChart
                    label={t("sales.profitability_growth")}
                    isNoComparison={isNoComparison}
                    data={chartsData.ProfitGrowth.Current || []}
                    unit={t("common.currency")}
                />
            </Grid>

            <Grid container spacing={0} flexWrap="nowrap">
                <ComparisonChart
                    label={t("sales.avg_basket_quantity")}
                    isNoComparison={isNoComparison}
                    data={chartsData.AvgBasketQuantity.Current || []}
                    unit={t("common.product_s")}
                    isAverage
                />
                <ComparisonChart
                    label={t("sales.avg_basket_value")}
                    isNoComparison={isNoComparison}
                    data={chartsData.AvgBasketValue.Current || []}
                    unit={t("common.currency")}
                    isAverage
                />
            </Grid>

            <Grid container spacing={0} flexWrap="nowrap">
                <ComparisonChart
                    label={t("sales.total_transactions")}
                    isNoComparison={isNoComparison}
                    data={chartsData.TotalTransactions.Current || []}
                    unit={""}
                />

                {/* <GridCard height={400}>
                    <OrderTypesPeriods />
                </GridCard> */}
            </Grid>
        </Stack>
    );
};

export { SalesPerformance };
